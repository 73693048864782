import React, {useEffect, useRef, useState} from "react";
import {
    Table,
    InputBox,
    Popconfirm,
} from "../components/Elements/appUtils";
import PageHeader from "../components/Elements/pageHeader";
import axios from "axios";
import moment from "moment";
import {getToken} from "../request";
import {showLoader, hideLoader} from "../actions/loader";
import {useDispatch} from "react-redux";
import {notification} from "../components/Elements/appUtils";
import {generateSale, getSaleByHeadBranch} from "./transfer/api";
import {getItemLocalstorage} from "../components/_utils/_utils";
import {findROIServiceUrl} from "./transfer/api";
import {useNavigate} from "react-router-dom";

function ROI() {
    let tableRef = useRef();
    const userType = getItemLocalstorage("user")["userType"];
    const currentDate = moment();
    const initialHandleDate = currentDate.format("YYYY-MM");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [handleDate, setHandleDate] = useState(initialHandleDate);
    const handleSearch = () => {
        apiRequest(handleDate);
        tableRef.current.reload();
    };

    const apiRequest = async () => {
        return new Promise(async (resolve) => {
            try {
                const data = await axios.get(getSaleByHeadBranch(), {
                    params: {dateString: handleDate,},
                    ...(await getToken()),
                });
                resolve(data.data);
            } catch (error) {
                console.error("Error getting the data:", error);
            }
        });
    };

    useEffect(() => {
        apiRequest(handleDate);
    }, []);

    useEffect(() => {
        handleSearch();
    }, []);

    const ConvertSale = async (
        customerId,
        royaltyAmount,
        royaltyPercentage,
        requestId
    ) => {
        try {
            const response = await axios.get(findROIServiceUrl(), await getToken());
            let config = {
                ...(await getToken()),
            };

            dispatch(showLoader());

            const data = await axios.post(
                generateSale(),
                {
                    customerId: customerId,
                    totalAmount: royaltyAmount,
                    royalty: royaltyPercentage,
                    requestId: requestId,
                    type: "royalty",
                    productReports: response.data.data,


                },
                config
            );

            dispatch(hideLoader());
            if (data.data.success) {
                notification.success({message: data.data.message});
            } else {
                notification.error({message: data.data.message});
            }
        } catch (error) {
            console.error("Error getting the data:", error);
        }
    };

    const columns = [
        {
            title: "Branch Name",
            dataIndex: "branchName",
            key: "branchName",
        },
        {
            title: "Total Sale",
            dataIndex: "totalAmount",
            key: "totalAmount",
            render: (text, record) => <>₹ {(record.totalAmount).toFixed(2)}</>,
        },
        {
            title: "Royalty",
            dataIndex: "royaltyPercentage",
            key: "royaltyPercentage",
            render: (text, record) => <> {record.royaltyPercentage} %</>,
        },

        {
            title: "Royalty Income",
            dataIndex: "royaltyAmount",
            key: "royaltyAmount",
            render: (text, record) => <>₹ {(record.royaltyAmount).toFixed(2)}</>,
        },
        {
            title: "Actions",
            dataIndex: "description",
            key: "description",

            render: (text, record) => (
                <>
                    {userType == "company" ? (
                        <Popconfirm
                            title={"Are your sure, you want to generate Roi?"}
                            okText="Yes"
                            cancelText="No"
                            className="btn btn-danger"
                            onConfirm={() => {
                                // ConvertSale(
                                //     record.customerId,
                                //     record.royaltyAmount,
                                //     record.royaltyPercentage,
                                //     record.branchId
                                // );
                                navigate(`/add-patient?royaltyAmount=${record.royaltyAmount && record.royaltyAmount}`);
                            }}
                        >
                            <button className="btn btn-info btn-xs">ROI Convert</button>
                        </Popconfirm>
                    ) : null}
                </>
            ),
        },
    ];

    return (
        <>
            <PageHeader title={"ROI"}>
                <div className="card">
                    <div className="card-body">
                        <div style={{display: "flex", alignItems: "center", gap: 0}}>
                            <InputBox title={"Select Date"}>
                                <input
                                    type="month"
                                    label="Select Date"
                                    value={handleDate}
                                    onChange={(e) => {
                                        setHandleDate(e.target.value); // Keep the value in YYYY-MM format
                                    }}
                                    className="form-control mb-3"
                                    style={{height: "45px", width: "230px"}}
                                />
                            </InputBox>
                            <button className="btn btn-info btn-sm mt-1" style={{marginLeft: "-9%"}}
                                    onClick={handleSearch}>
                                Search
                            </button>
                        </div>
                        <Table apiRequest={apiRequest} columns={columns} ref={tableRef}/>
                    </div>
                </div>
            </PageHeader>
        </>
    );
}

export default ROI;
