import {getDayBookUrl, getGroupedDataUrl} from "../api"
import {customAxios as axios, getToken} from "../../../request";
import { showLoader, hideLoader } from "../../../actions/loader";


export const fetchDayBookFxn = async (valData) => {
    showLoader(); 
    let config = {
        params: {...valData},
        ...(await getToken()),
    };
    const {data} = await axios.get(getDayBookUrl(), config);
    hideLoader();
    return data;
};

export const fetchGroupedDaybookFxn = async (valData) => {
    showLoader(); 
    let config = {
        params: {...valData},
        ...(await getToken()),  
    };
    const data = await axios.get(getGroupedDataUrl(), config);
    hideLoader();
    return data;
};


  