import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Select2,
  InputBox,
  Card,
  Modal,
  notification,
  calculation,
} from "../../../components/Elements/appUtils";
import TableMain from "../../../components/Elements/Table";
import { fetchRequestedProductReport } from "../../stockRequest/api/index";
import { getToken } from "../../../request";
import {
  getProductDataUrl,
  getPatientServiceDataUrl,
} from "../../products/api";
import {getUpdatedServicePriceCheckList} from "../../updatedServicePrice/actions";

let initState = {
  productId: "",
  productName: "",
  productType: "",
  quantity: 1,
  price: "",
  salePrice: "",
  inclusivePrice: "",
  hsn: "",
  subTotal: "",
  discount: "",
  discountAmount: "",
  expiryDate: "",
  manufacturingDate: "",
  taxableAmount: "",
  igstAmount: "",
  cgstAmount: "",
  sgstAmount: "",
  igstTax: "",
  gst: "",
  totalAmount: "",
  gstAmount: "",
  netAmount: "",
  stateOfSupply: "",
  reportType: "Inclusive",
  transactionType: "",
};

const ItemListComponent = (props) => {
  let {
    ProductReport,
    updateProductReport,
    statesAreEqual,
    requestId,
    stateOfSupply,
    companyState,
    royaltyAmount
  } = props;
  const [service, setService] = useState([]);
  let [Report, setReport] = useState("");
  const [isModal2Open, setIsModal2Open] = useState(false);
  let [productState, setProductState] = useState(initState);
  const [newServicePriceData, setNewServicePriceData] = useState([]);

  const findNewPriceAvailable = (params) => {
    return new Promise(async () => {
      const data = await getUpdatedServicePriceCheckList({...params});
      setNewServicePriceData(data.data)
    });
  };
  useEffect(() => {
    findNewPriceAvailable()
  }, []);

  const fetchService = async () => {
    try {
      const response = await axios.get(getPatientServiceDataUrl(), getToken());
      setService(response.data.data);
    } catch (error) {
      console.error("Error getting the data:", error);
    }
  };

  useEffect(() => {
    reCalculateAmount();
  }, [
    productState.productId,
    productState.reportType,
    productState.price,
    productState.quantity,
    productState.discount,
    productState.gst,
  ]);

  const reCalculateAmount = () => {
    calculation({ ...productState }, stateOfSupply, companyState).then(
      (resp) => {
        setProductState({
          ...productState,
          ...resp,
        });
      }
    );
  };

  useEffect(() => {
    fetchService();
  }, []);

  const onSubmit = () => {
    if (!productState.productId) {
      notification.warning({ message: "Please Enter Service" });
      return;
    }
    if (!productState.price) {
      notification.warning({ message: "Please Enter Price" });
      return;
    }
    if (!productState.quantity) {
      notification.warning({ message: "Please Enter Quantity" });
      return;
    }
    if (productState.discount > 100) {
      notification.warning({
        message: "Please Enter A Valid Discount Percentage",
      });
      return;
    }

    const updatedProductReport = [
      ...ProductReport,
      { ...productState, serialNo: ProductReport.length + 1 },
    ];
    updateProductReport(updatedProductReport);

    setProductState(initState);
  };

  const handleChange1 = (e, fieldName) => {
    const { value } = e.target;
    let obj = {};
    if (fieldName == "productId") {
      const newServicePrice = newServicePriceData.find((prod) => {
            return prod.productId._id === value
          }
      );
      const selectedProduct = service.find((prod) => prod._id === value);

      if (
        selectedProduct.type === "Slip" ||
        selectedProduct.type === "Membership"
      ) {
        const isSlipOrMembershipAlreadyAdded = ProductReport.some((element) => {
          return (
            element.productType === "Slip" ||
            element.productType === "Membership"
          );
        });

        if (isSlipOrMembershipAlreadyAdded) {
          notification.warning({
            message: "Slip or Membership is already added",
          });
          return;
        }
      }
      if (selectedProduct.type === "Service") {
        const isServiceAlreadyAdded = ProductReport.some((element) => {
          return element.productId == selectedProduct._id;
        });

        if (isServiceAlreadyAdded) {
          notification.warning({ message: "Same Service is already added" });
          return;
        }
      }

      obj = {
        productName: selectedProduct.name,
        price: newServicePrice && newServicePrice.price? newServicePrice.price : selectedProduct.price,
        hsn: selectedProduct.hsnCode,
        gst: selectedProduct.gst,
        productType: selectedProduct.type,
      };
    }

    setProductState({
      ...productState,
      [fieldName]: value,
      ...obj,
    });
  };

  let Cgst = {
    title: "CGST",
    dataIndex: "cgstAmount",
    key: "cgstAmount",
    render: (text, record) => (
      <span>
        {record.gst / 2}% (Rs.{text}){" "}
      </span>
    ),
  };

  let Sgst = {
    title: "SGST",
    dataIndex: "sgstAmount",
    key: "sgstAmount",
    render: (text, record) => (
      <span>
        {record.gst / 2}% (Rs.{text}){" "}
      </span>
    ),
  };

  let Igst = {
    title: "IGST",
    dataIndex: "igstTax",
    key: "igstTax",
    render: (text, record) => (
      <span>
        {text}% (Rs.{record.igstAmount}){" "}
      </span>
    ),
  };

  let NetAmount = {
    title: "Net Amount",
    dataIndex: "netAmount",
    key: "netAmount",
    render: (text) => `Rs. ${text}`,
  };

  let action = {
    title: "Action",
    key: "delete",
    render: (text, record, index) => (
      <a className="nav-link" onClick={() => handleDelete(index)}>
        <i className="mdi mdi-delete"/>
      </a>
    ),
  };

  const columns = [
    {
      title: "Sr. No",
      dataIndex: "serialNo",
      key: "serialNo",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "Type",
      dataIndex: "reportType",
      key: "reportType",
    },
    {
      title: "HSN",
      dataIndex: "hsn",
      key: "hsn",
    },
    {
      title: "Qty.",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Rate",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Total",
      dataIndex: "totalAmount",
      key: "totalAmount",
      render: (text) => `Rs.${text}`,
    },
    {
      title: "Dis(%)",
      dataIndex: `discount`,
      key: "discount",
      render: (text) => <span>{text}%</span>,
    },
    {
      title: "Dis Amt.",
      dataIndex: `discountAmount`,
      key: "discountAmount",
      render: (text) => <span>Rs.{text}</span>,
    },
    {
      title: "Taxable Amount",
      dataIndex: "taxableAmount",
      key: "taxableAmount",
      render: (text) => `Rs. ${text}`,
    },
  ];

  const handleDelete = (index) => {
    const updatedProductReport = [...ProductReport];
    updatedProductReport.splice(index, 1);
    updateProductReport(updatedProductReport);
  };

  if (statesAreEqual == true) {
    columns.push(Cgst, Sgst, NetAmount, action);
  } else {
    columns.push(Igst, NetAmount, action);
  }

  const fetchRequestedProductReport1 = async () => {
    try {
      let config = {
        params: { requestId },
        ...(await getToken()),
      };
      const response = await axios.get(fetchRequestedProductReport(), config);
      setReport(response.data.data);
    } catch (error) {
      console.error("Error getting the data:", error);
    }
  };

  useEffect(() => {
    fetchRequestedProductReport1();
  }, [requestId]);

    useEffect(() => {
        if (royaltyAmount) {
            const royaltyService = service.find(
                (service) => service.name == "Royality"
            );
            if (royaltyService) {
                setProductState({
                    ...productState,
                    productName: royaltyService.name,
                    price: Number(royaltyAmount).toFixed(2),
                    productType: royaltyService.type,
                    gst: royaltyService.gst,
                    hsn: royaltyService.hsnCode,
                    productId: royaltyService._id,
                });
            }
        }
    }, [royaltyAmount, service]);

  return (
    <>
      <Card title={"Select Services"} size={"small"}>
        <div className={"item-flex"}>
          <InputBox title={"Services"} className={"flex2"}>
            <select
              style={{ height: "45px" }}
              className="form-control"
              value={productState.productId}
              onChange={(e) => handleChange1(e, "productId")}
              required
            >
              <option value="">Select Services</option>
              {service.map((service) => (
                <option key={service._id} value={service._id}>
                  {service.name}
                </option>
              ))}
            </select>
          </InputBox>
          <InputBox title={"Price"}>
            <input
              className={"form-control"}
              type="number"
              placeholder="Price"
              value={productState.price}
              onChange={(e) => handleChange1(e, "price")}
            />
          </InputBox>
          <InputBox title={"Dis(%)"}>
            <input
              className={"form-control"}
              type="number"
              value={productState.discount}
              placeholder="Discount"
              onChange={(e) => handleChange1(e, "discount")}
              max="100"
            />
          </InputBox>
          <InputBox title={"GST(%)"}>
            <input
              style={{ height: "45px", color: "black" }}
              disabled
              className={"form-control"}
              type="text"
              value={productState.gst}
              placeholder="GST"
              onChange={(e) => handleChange1(e, "gst")}
            />
          </InputBox>

          <InputBox className={"flex_none"}>
            <a
              className="addBtn fs-5 btn-link"
              onClick={(e) => {
                e.preventDefault();
                onSubmit();
              }}
            >
              <i className={`mdi mdi-library-plus menu-icon`}/>
            </a>
          </InputBox>
        </div>
      </Card>
      <div key={ProductReport.length} className="mt-4 mb-4">
        <div className="d-flex">
          <h4 className="m-2">Items List</h4>
          {requestId ? (
            <a
              className="btn btn-link m-2"
              onClick={() => {
                setIsModal2Open(true);
              }}
            >
              Requested Products
            </a>
          ) : null}
        </div>
        <TableMain dataSource={ProductReport} columns={columns} />
      </div>

      {isModal2Open && (
        <Modal
          title={"Products"}
          width={"50%"}
          visible={isModal2Open == true}
          onClose={() => setIsModal2Open(false)}
        >
          <table class="table table-bordered mt-4">
            <thead>
              <td>Sr no.</td>
              <td>Product Name</td>
              <td>Qty.</td>
              <td>HSN</td>
            </thead>
            <tbody>
              {Report.map((item, index) => (
                <tr key={item.id}>
                  <td>{index + 1}</td>
                  <td>{item.productName}</td>
                  <td>{item.quantity}</td>
                  <td>{item.hsn}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal>
      )}
    </>
  );
};
export default ItemListComponent;
