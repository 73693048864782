import React, {useEffect, useRef, useState} from "react";
import {Table, DefaultTablePagination} from "../../components/Elements/appUtils";
import {fetchDayBookFxn, fetchGroupedDaybookFxn} from "./action"
import PageHeader from "../../components/Elements/pageHeader";
import moment from "moment";
import SearchCustomer from "../customer/chooseCustomer/searchCustomer";

// Name	Payment Type	Group Name	Gst	Type	Transaction Type	Quantity	Recieved Amount	Net Amount	Balance
function DayBook() {
    const [customerId, setCustomerId] = useState("")
    let [total, setTotal] = useState("")
    let [dates, setDates] = useState({
        fromDate: moment().startOf('day').format('YYYY-MM-DD'),
        toDate: moment().endOf('day').format('YYYY-MM-DD'),
    });

    const [isCurrentMonth, setIsCurrentMonth] = useState(false);
    const [groupedByTable, setGroupedByTable] = useState(false);
    const [groupedData, setGroupedData] = useState([]);
    let tableRef = useRef();
    let tableRef2 = useRef();

    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            try {
                if (dates.fromDate && dates.toDate) {
                    const fromDateStartOfDay = new Date(dates.fromDate);
                    fromDateStartOfDay.setHours(0, 0, 0, 0);
                    const toDateEndOfDay = new Date(dates.toDate);
                    toDateEndOfDay.setHours(23, 59, 59, 999);
                    params.date = {
                        $gte: new Date(fromDateStartOfDay),
                        $lte: new Date(toDateEndOfDay),
                    };
                } else {
                    const today = new Date();
                    today.setHours(0, 0, 0, 0);
                    const endOfDay = new Date();
                    endOfDay.setHours(23, 59, 59, 999);
                    params.date = {
                        $gte: today,
                        $lte: endOfDay,
                    };
                }

                if (customerId) {
                    params.customerId = customerId;
                }

                const data = await fetchDayBookFxn({...params});
                setTotal(data.total)
                resolve(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        });
    };
    const groupedByData = async () => {
        let params = {}
        try {
            if (dates.fromDate && dates.toDate) {
                params.fromDate = moment(dates.fromDate).startOf('day');
                params.toDate = moment(dates.toDate).endOf('day');
            } else {
                params.fromDate = moment().startOf('day');
                params.toDate = moment().endOf('day');
            }

            if (customerId) {
                params.customerId = customerId;
            }

            const data = await fetchGroupedDaybookFxn(params);
            
            const filteredData = data.data.filter(item => 
                item.data[0]?.customerDetails?.name !== "Indirect Expenses"
            );
            
            setGroupedData(filteredData);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const columns = [
        {
            title: "Sr. No",
            dataIndex: "serialNo",
            key: "serialNo",
            render: (text, record, index) => index + 1,
        },
        {
            title: "Date",
            dataIndex: "date",
            key: "date",
            render: (text) => <span>{moment(text).format("DD-MMM-YY")}</span>,
        },
        {
            title: "Name",
            dataIndex: "customerId",
            key: "customerId",
            render: (text) => (
                <span>
                    {text?.name}
                    {text?.mobile && ` (${text.mobile})`}
                </span>
            )
        },
        {
            title: "UHID",
            dataIndex: "customerId",
            key: "customerId",
            render: (text) => (
                <span>
                    {text?.uhid}
                </span>
            )
        },
        // {
        //     title: "Number",
        //     dataIndex: "customerId",
        //     key: "customerId",
        //     width:'90px',
        //     render: (text) => <span>{text?.mobile}</span>,
        // },

        // {
        //     title: "Payment Type",
        //     dataIndex: "paymentType",
        //     key: "paymentType",
        //     render: (text, record, index) => {
        //
        //         if (record.salesId) {
        //             return (
        //                 <>
        //                     {record.salesId?.paidToUser?.map((item) => (
        //                         <div key={item._id}>
        //                             {item.paymentType}
        //                         </div>
        //                     ))}
        //                 </>
        //             );
        //         } else if (record.purchaseId) {
        //
        //             return (
        //                 <div>
        //                     {record.purchaseId.paymentType}
        //                 </div>
        //             );
        //         } else {
        //             return null;
        //         }
        //     }
        //
        //
        // },
        // {
        //     title: "Group Name",
        //     dataIndex: "groupName",
        //     key: "groupName",
        //     render: (text, record, index) => {
        //
        //         if (
        //             record.customerId &&
        //             (record.customerId.customerGroupName === "BANK ACCOUNT" || record.customerId.customerGroupName === "CASH IN HAND")
        //         ) {
        //             return record.customerId.customerGroupName;
        //         } else {
        //             return null;
        //         }
        //     }
        // },
        // {
        //     title: "Gst",
        //     dataIndex: "gstAmount",
        //     key: "gstAmount",
        //     render: (text, record, index) => {
        //
        //         if (record.salesId) {
        //             return (
        //                 <>
        //                     {record.salesId.gstAmount}
        //                 </>
        //             );
        //         } else if (record.purchaseId) {
        //
        //             return (
        //                 <div>
        //                     {record.purchaseId.gstAmount}
        //                 </div>
        //             );
        //         } else {
        //             return null;
        //         }
        //     }
        // },

        {
            title: "Details",
            dataIndex: "type",
            key: "type",
            render: (text, record, index) => {
                return (
                    <>
                        <span>{record?.type}</span>
                        <span>{record && record.transferToId && ` ➡️ ${record.transferToId?.name}`}</span>

                    </>
                )
            }
        },
        // {
        //     title: "Bank Name",
        //     dataIndex: "bankId",
        //     key: "bankId",
        //     render: (transactionType, record) => {
        //         return    <div>
        //      {record.salesId.paidToUser.map((item) => {
        //             return(
        //                 <>
        //                <span>{getBankNameById(item._id, bankDatas)}</span>
        //                 </>
        //             )
        //         })
        //     }
        //        </div>;
        //     }
        // },

        {
            title: "Credit",
            dataIndex: "transactionType",
            key: "credit",
            render: (transactionType, record) => {
                if (transactionType === "Credit") {
                    return <div style={{color: '#03C03C', padding: '5px', fontWeight: 'bold'}}>{record.netAmount}</div>;
                }
                return null;
            },
        },
        {
            title: "Debit",
            dataIndex: "transactionType",
            key: "debit",
            render: (transactionType, record) => {
                if (transactionType === "Debit") {
                    return <div style={{color: 'red', padding: '5px', fontWeight: 'bold'}}>{record.netAmount}</div>;
                }
                return null;
            },
        },
        // {
        //     title: "Recieved Amount",
        //     dataIndex: "paidAmount",
        //     key: "gstAmount",
        //     render: (text, record, index) => {
        //
        //         if (record.salesId) {
        //             return (
        //                 <>
        //                     {record.salesId.paidAmount}
        //                 </>
        //             );
        //         } else if (record.purchaseId) {
        //
        //             return (
        //                 <div>
        //                     {record.purchaseId.paidAmount}
        //                 </div>
        //             );
        //         } else {
        //             return null;
        //         }
        //     }
        // },
        // {
        //     title: "Amount",
        //     dataIndex: "netAmount",
        //     key: "netAmount",
        //     render: (text) => <span>Rs. {text}</span>,
        //
        // },
    ];
    const groupedDataColumn = [
        {
            title: "Sr. No",
            dataIndex: "serialNo",
            key: "serialNo",
            render: (text, record, index) => index + 1,
        },
        {
            title: "Date",
            dataIndex: "date",
            key: "date",
            render: (text , record) => <span>{moment(record.data[0].date).format("DD-MMM-YY")}</span>,
        },
        {
            title: "Name",
            dataIndex: "customerId", 
            key: "customerId",
            render: (text,record) => {
                return (
                    <span>
                        {record?.data[0]?.customerDetails?.name}
                        &nbsp;({record?.data[0]?.customerDetails?.type?.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')})
                        {record?.data[0]?.customerDetails?.mobile && ` (${text?.data[0]?.customerDetails?.mobile})`}
                    </span>
                );
            }
        },
        {
            title: "Bill Amount",
            dataIndex: "data",
            key: "amount", 
            render: (data) => {
                const total = data.reduce((sum, item) => {
                    if (item.type === 'sale') {
                        return sum + (item.totalNetAmount || 0);
                    }
                    return sum;
                }, 0);

                const totalSale = data.reduce((sum, item) => {
                    if (item.type === 'sale') {
                        return sum + 1
                    }
                    return sum;
                }, 0);
                return (
                    <>
                    <div>
                        {/* {data.filter(item => item.type === 'sale').map((item, index) => (
                            <div key={index}>
                                {`${item.type} - ${item.totalNetAmount}`}
                            </div>
                        ))} */}
                        {
                            total > 0 && (  
                                <>
                                    <div>Sale Amount: Rs. {total}</div>
                                    <div>Total Sale Count: {totalSale}</div>
                                </>
                            )
                        }
                        
                    </div>
                    </>
                );
            }
        },
        {
            title: "Total Received",
            dataIndex: "data",
            key: "totalReceived", 
            render: (data) => {
                const total = data.reduce((sum, item) => {
                    if (item.paymentType) {
                        return sum + (item.totalNetAmount || 0);
                    }
                    return sum;
                }, 0);
                return <span>Rs. {total}</span>;
            }
        },
        {
            title: "Received Amount",
            dataIndex: "data", 
            key: "amount",
            render: (data) => {
                // Group by payment type and sum amounts
                const paymentTypeSums = data.reduce((acc, item) => {
                    if (item.paymentType) {
                        acc[item.paymentType] = (acc[item.paymentType] || 0) + (item.totalNetAmount || 0);
                    }
                    return acc;
                }, {});

                return (
                    <div>
                        {Object.entries(paymentTypeSums).map(([paymentType, sum], index) => (
                            <div key={index}>
                                {`${paymentType} - ${sum}`}
                            </div>
                        ))}
                    </div>
                );
            }
        }
    ];

    const clearDayBookFilters = () => {
        setIsCurrentMonth(false);
        groupedByData()
        setCustomerId('')
        setDates({
            fromDate: "",
            toDate: "",
        })
        if(tableRef.current){
            tableRef.current.reload()
        }
    }

    const Print = () => {
        let printContents = document.getElementById('printable').innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
        window.location.reload()
    }


    const handleFilter = async () => {
        setGroupedByTable(false);
        if (tableRef.current) { 
            tableRef.current.reload();
        }
        await groupedByData();
    };

    const handleCheckboxChange = (e) => {
        setIsCurrentMonth(e.target.checked);
        if (e.target.checked) {
            const startOfMonth = new Date();
            startOfMonth.setDate(1);
            const endOfMonth = new Date();
            endOfMonth.setMonth(endOfMonth.getMonth() + 1);
            endOfMonth.setDate(0);

            setDates({
                fromDate: startOfMonth.toISOString().split('T')[0],
                toDate: endOfMonth.toISOString().split('T')[0]
            });
            setTimeout(() => {
                handleFilter()
                groupedByData();
            }, 300);
        } else {
            setDates({
                fromDate: '',
                toDate: ''
            });
        }
    };

    useEffect(() => {
        setTimeout(() => {
            handleFilter()
        }, 300);

    }, [isCurrentMonth]);

    useEffect(() => {
        groupedByData()
    }, [groupedByTable, dates.fromDate, dates.toDate, customerId]);

    return (
        <PageHeader
            title={`Day book (total : ${total})`}
        >
            <div className="card">
                <div className="card-body">
                    <div className="mb-3 row">
                        <div className={"col-md-2"} style={{marginTop: "13px"}}>
                            <SearchCustomer
                                type={["customer", "patient"]}
                                customerId={customerId}
                                callback={(clientData) => {
                                    if (clientData && clientData._id) {
                                        setCustomerId(clientData._id);
                                    }
                                }}
                            />
                        </div>
                        <div className="col-md-2">
                            <label htmlFor="searchCategory" className="form-label"/>
                            <input
                                type="date"
                                className="form-control "
                                value={dates.fromDate}
                                onChange={(e) =>
                                    setDates({...dates, fromDate: e.target.value})
                                }
                            />
                        </div>
                        <div className="col-md-2">
                            <label htmlFor="searchCategory" className="form-label"/>
                            <input
                                type="date"
                                className="form-control "
                                value={dates.toDate}
                                onChange={(e) => setDates({...dates, toDate: e.target.value})}
                            />
                        </div>
                    </div>
                    <div className="mb-3 search-buttons">
                        <div className="search-wrap pe-2">
                            <button className="btn btn-info" onClick={handleFilter}>
                                Search
                            </button>
                        </div>
                        <div className="search-wrap pe-2">
                            <button className="btn btn-secondary" onClick={() => {
                                clearDayBookFilters()
                            }}>
                                Clear
                            </button>
                        </div>
                        <div className="search-wrap">
                            <button className="btn btn-info" onClick={Print}>
                                Print
                            </button>
                        </div>
                        <div className="col-md-6 mt-10">
                            <button
                                type="button"
                                className={`btn ${groupedByTable ? 'btn-info' : 'btn-secondary'}`}
                                onClick={() => setGroupedByTable((prev) => !prev)}
                            >
                                {groupedByTable ? "Grouped By Type (Selected)" : "Group By Type"}
                            </button>
                        </div>
                    </div>
                    <div>
                        <div className="col-md-6 mt-10">
                            <label htmlFor="searchCategory" className="form-label"/>
                            <input
                                type="checkbox"
                                id="currentMonth"
                                checked={isCurrentMonth}
                                onChange={handleCheckboxChange}
                            />
                            <label htmlFor="currentMonth" className="ms-1">Current Month Data</label>
                        </div>
                    </div>


                    {groupedByTable !== undefined && (
                        groupedByTable ? (
                            <div id="printable" key={groupedByTable} style={{display: groupedByTable ? 'block' : 'none'}}>
                                <Table
                                    dataSource={groupedData}
                                    columns={groupedDataColumn}
                                    ref={tableRef2}
                                />
                            </div>
                        ) : (
                            <div key={groupedByTable} id="printable"
                                 style={{display: !groupedByTable ? 'block' : 'none'}}>
                                <Table
                                    apiRequest={apiRequest}
                                    columns={columns}
                                    pagination={DefaultTablePagination({}, 500)}
                                    ref={tableRef}
                                    position="top"
                                />
                            </div>
                        )
                    )}

                </div>
            </div>
        </PageHeader>
    );
}

export default DayBook;
