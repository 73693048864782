import React, { useEffect, useRef, useState } from "react";
import {
  DefaultTablePagination,
  Modal,
  notification,
  Popconfirm,
  Table,
  Tooltip
} from "../../components/Elements/appUtils";
import {fetchCreditNote, fetchCreditNoteForCsv, fetchSale} from "./actions";
import PageHeader from "../../components/Elements/pageHeader";
import moment from "moment";
import { getToken } from "../../request";
import { getProductReport } from "../purchase/api";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../actions/loader";
import SearchCustomer from "../customer/chooseCustomer/searchCustomer";
import {getStateByCountry} from "../../components/_utils/appUtils";
import {fetchSaleCategory} from "../category/actions";
import log from "async";
import {fetchSaleForCsv} from "../sales/actions";

function CreditNoteList() {
  let tableRef = useRef();
  const dispatch =  useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [creditNoteId, setCreditNoteId] = useState(null);
  const [Report, setReport] = useState([]);
  const navigate = useNavigate();
  const [customerData, setCustomerData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [showFilter, setShowFilter] = useState(false)
  const [searchVendor, setSearchVendor] = useState("")
  const [searchInvoice, setSearchInvoice] = useState("")
  const [searchCreditDate, setSearchCreditDate] = useState("")
  const [searchState, setSearchState] = useState("")
  const [final,setFinal] = useState('');
  const [saleCategory, setSaleCategory] = useState([]);
  const [saleCategoryId, setSaleCategoryId] = useState("");
  let [dates, setDates] = useState({
    fromDate: "",
    toDate: "",
  });

  const [customerDetails, setCustomerDetails] = useState({
    customerName: "",
    customerState: "",
    customerInvoice: "",
    customerNumber: "",
    purchaseDate: "",
  });
  const [count, setCount] = useState({total:0, totalAmountSum:0,totalDiscSum:0});
  const [customerId, setCustomerId] = useState(0);
  const [searchByState, setSearchByState] = useState({
    stateCode: '',
    stateName: ''
  });
  const [stateList, setStateList] = useState([]);
  const fetchSaleFrom = async () => {
    const {data} = await fetchSaleCategory({});
    setSaleCategory(data);
  };

  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      try {
        if(customerId)
        {
          params['customerId'] =  customerId;}
        if (searchByState.stateName && searchByState.stateCode) {
          params.stateOfSupply = searchByState.stateCode;
        }
        if(searchInvoice)
          params['invoiceNumber'] = searchInvoice;
        if(saleCategoryId)
        {
          params.saleCategory=saleCategoryId;
       }
        if (dates.fromDate && dates.toDate) {

          params.creditNoteDate ={
            $gte: moment(dates.fromDate).startOf("day").toISOString(),
            $lte: moment(dates.toDate).endOf("day").toISOString(),
          };
        }

        console.log('param',params)
        const data = await fetchCreditNote({ ...params });

        setCustomerData(data.data);
        setFinal(data.data);
        const total = data.total;
        const totalAmountSum = data.totalAmountSum;
        const totalDiscSum = data.totalDiscSum;
        setCount({total, totalAmountSum:totalAmountSum , totalDiscSum:totalDiscSum});
        resolve(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    });
  };
  useEffect(()=>{
    fetchSaleFrom();
  },[])
  const fetchcustomerdata = () => {
    const allrecordofcustomer = customerData.filter(
      (item) => item._id == creditNoteId
    );

    allrecordofcustomer.map((i) => {
      console.log(i,"cafpjpj");
      setCustomerDetails({
        customerName: i.customerId.name,
        customerState: i.customerId.state,
        customerNumber: i.customerId.mobile,
        customerInvoice: i.invoiceNumber,
        purchaseDate: i.purchaseDate,
      });
      return;
    });
  };

  const fetchProductReport = async () => {
    try {
      let config = {
        params: { creditNoteId },
        ...(await getToken()),
      };
      dispatch(showLoader())
      const response = await axios.get(getProductReport(), config);
      setReport(response.data.data);
      dispatch(hideLoader())
    } catch (error) {
      console.error("Error getting the data:", error);
    }
  };

  useEffect(() => {
    apiRequest();
  }, []);

  useEffect(() => {
    fetchProductReport();
    fetchcustomerdata();
  }, [creditNoteId]);

  const columns = [
    {
      title: "Sr. No",
      dataIndex: "serialNo",
      key: "serialNo",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Vendor",
      dataIndex: "customerId",
      key: "customerId",
      render: (text) => <span>{text?.name}</span>,
    },
    {
      title: "Invoice Number",
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
    },
    {
      title: "Credit Note Date",
      dataIndex: "creditNoteDate",
      key: "creditNoteDate",
      render: (text) => <span>{moment(text).format("DD/MMM/YY")}</span>,
    },
    {
      title: "State",
      dataIndex: "customerId",
      key: "customerId",
      render: (text) => <span>{text?.state}</span>,
    },
    {
      title: "Total Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
      render: (text) => <span>Rs. {text}</span>,
    },

    {
      title: "sale Category",
      dataIndex: "saleCategory",
      key: "saleCategory",
      render: (text, record) => {
        return (
            <>
              {record && record.saleCategory &&
               record.saleCategory.name ?
               record.saleCategory.name
               : null}
            </>)
      },
    },
    {
      title: "Received Amount",
      dataIndex: "paidAmount",
      key: "paidAmount",
      render: (text) => <span>Rs. {text}</span>,
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      render: (text) => <span>Rs. {text}</span>,
    },
    {
      title: "Actions",
      dataIndex: "description",
      key: "description",
      render: (text, record) => (
        <>
          <Tooltip title={"View"}>
            <a
              className={"empty_btn"}
              onClick={() => {
                setIsModalOpen(true);
                setCreditNoteId(record._id);
              }}
            >
              <i className={"fa fa-eye far "}></i>
            </a>
          </Tooltip>
          <Tooltip title={"Print"}>
            <a
                href={`/print-creditNote?_id=${record._id}`}
                target="_blank"
                rel="noreferrer"
            >
              <img src={"./printer-50.png"} className="print" alt={""}/>
            </a>
          </Tooltip>
        </>
      ),
    },
  ];
  const filterCreditNote = () => {
    setShowFilter(!showFilter);
  };
  const clearCreditFilters = () => {
    setSearchVendor("");
    setSearchInvoice("");
    setSearchCreditDate("");
    setCustomerId('');
    setSaleCategoryId("");
    setDates({
      fromDate: '',
      toDate: ''
    });
    setSearchByState({
      stateName: '',
      stateCode: ''
    });
    setShowFilter(!showFilter);
   // setCustomerData(filteredData);
  };

  const printFxn = () => {
    const printContents = document.getElementById("printable").innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
  };
  const handleConvertToCsv = async () => {
    try {
      if (!dates.fromDate) {
        notification.error({ message: 'Enter the starting date!' });
        return;}let toDate = dates.toDate ? dates.toDate : moment();
      const params = {
        creditNoteDate: {
          $gte: moment(dates.fromDate).startOf("day").toISOString(),
          $lte: moment(toDate).endOf("day").toISOString(),
        }
      };
      const csvBlob = await fetchCreditNoteForCsv(params);
      if (csvBlob instanceof Blob) {
        const url = window.URL.createObjectURL(csvBlob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'sales_data.xlsx'); // Adjust file extension if needed
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        throw new Error('The fetched data is not a valid Blob');
      }
    } catch (error) {
      console.error('Error handling CSV conversion:', error);
      notification.error({ message: 'Error converting data to CSV!' });
    }
  };

  console.log('show',showFilter)
  const events = {
    getState: () => {
      let resp = getStateByCountry('India');
      setStateList(resp);
    },
  };
  useEffect(() => {
    events.getState();
  }, []);

  console.log('show',showFilter)
  return (
    <PageHeader
      title={<div style={{ display: 'flex', alignItems: 'center' }}>
        <div>Credit Note</div>
      </div>}
      extraLink={[
        {
          name: "Add Credit-Note",
          link: "/addcreditNote",
        },
      ]}
    >
      <div className="card">
        <div className="card-body">
        <div className="mb-3 row">
          {/*<div className={"col-md-2 pe-0 search-filters"} style={{marginTop: "13px"}}>*/}
          {/*  <SearchCustomer*/}
          {/*      type={["customer", "patient"]}*/}
          {/*      customerId={customerId}*/}
          {/*      callback={(clientData) => {*/}
          {/*        if (clientData && clientData._id) {*/}
          {/*          setCustomerId(clientData._id);*/}
          {/*        }*/}
          {/*      }}*/}
          {/*  />*/}
          {/*</div>*/}

          <div className="col-md-2 mb-2 pe-0" style={{ paddingLeft: '7px' }}>
            <div className={"col-md-12 pe-0"} style={{marginTop: "13px",height: "45px"}}>
              <SearchCustomer
                  type={["customer", "patient"]}
                  customerId={customerId}
                  callback={(clientData) => {
                    if (clientData && clientData._id) {
                      setCustomerId(clientData._id);
                    }
                  }}
              />
            </div>
          </div>

          <div className="col-md-2 mb-2 pe-0" style={{ paddingLeft: '10px' }}>

          <input
            type="text"
            className="form-control search-invoice-input "
            style={{marginTop: "17px",height: "45px"}}
            placeholder={'Search by Invoice'}
            value={searchInvoice}
            onChange={(e) => setSearchInvoice(e.target.value)}
          />
        </div>


        <div className="col-md-2 mb-2 pe-0" style={{ paddingLeft: '10px' }}>
          <div className="col-md-12 " style={{marginTop: 17}}>
            <select
                placeholder="State Of Supply"
                style={{height: "45px"}}
                className="form-control"
                value={searchByState.stateName} // Assuming searchByState is your state object
                onChange={(e) => {
                  const selectedState = stateList.find(state => state.name === e.target.value);
                  setSearchByState({
                    ...searchByState,
                    stateName: selectedState ? selectedState.name : '',
                    stateCode: selectedState ? selectedState.stateCode : ''
                  });
                }}
                required
            >
              <option value="">State Of Supply</option>
              {stateList.map((state) => (
                  <option key={state.name} value={state.name}>
                    {state.name}
                  </option>
              ))}
            </select>

          </div>
        </div>
        <div className="col-md-2 mb-2 pe-0" style={{ paddingLeft: '10px' }}>
          <div className="col-md-12 " style={{marginTop: 17}}>
            <select

                style={{height: "45px"}}
                className="form-control"
                onChange={({ target: { value } }) => {setSaleCategoryId(value);}}
                value={saleCategoryId}
            >
              <option value="" disabled selected>
                Select Sale Category
              </option>
              {saleCategory.map((category) => (
                  <option key={category._id} value={category._id}>
                    {category.name}
                  </option>
              ))}
            </select>

          </div>
        </div>
          <div className="col-md-2">
            <label htmlFor="searchCategory" className="form-label"/>
            <input
                type="date"
                className="form-control "
                name="name"
                value={dates.fromDate}
                onChange={(e) =>
                    setDates({...dates, fromDate: e.target.value})
                }
            />
          </div>
          <div className="col-md-2" style={{marginBottom: 10, marginTop: 5}}>
            <label htmlFor="searchCategory" className="form-label"/>
            <input
                type="date"
                className="form-control "
                name="name"
                value={dates.toDate}
                onChange={(e) => setDates({...dates, toDate: e.target.value})}
            />
          </div>
      </div>

      <div className="mb-3 search-buttons">
        <div className="search-wrap pe-2">
          <button className="btn btn-info" onClick={filterCreditNote}>
            Search
          </button>
        </div>
        <div className="search-wrap">
          <button className="btn btn-secondary" onClick={clearCreditFilters}>
            Clear
          </button>
        </div>
        <div className="search-wrap">
          <button className="btn btn-success" onClick={printFxn}>
            Print
          </button>
        </div>
        <div className="search-wrap">
          <Popconfirm
              title="Are you sure you want to export the data?"
              onConfirm={handleConvertToCsv}
              okText="Yes"
              cancelText="No"
          >
            <button className="btn btn-dark">
              Export
            </button>

          </Popconfirm>
        </div>
      </div>
          <div  id="printable">
            <div style={{ display: 'flex', alignItems: 'end' }}>
              <div style={{ fontSize:"smaller", marginTop:"5px" }}>
                <b>Count :</b>  {count.total}  &nbsp;&nbsp;&nbsp; <b>Total Amount :</b> ₹ {(count.totalAmountSum).toFixed(2)} &nbsp;&nbsp;&nbsp; <b>Total Disc :</b> ₹ {count.totalDiscSum}
              </div>
            </div>
            {showFilter?<div> <Table
                apiRequest={apiRequest}
                position={"top"}
                ref={tableRef}
                columns={columns}
                pagination={{...DefaultTablePagination(), defaultPageSize: 50}}
            /></div>:<div>
              <div> <Table
                  apiRequest={apiRequest}
                  position={"top"}
                  ref={tableRef}
                  columns={columns}
                  pagination={{...DefaultTablePagination(), defaultPageSize: 50}}
              /></div>
            </div>}

      </div>
        </div>
      </div>
      {isModalOpen && (
        <Modal
          title={"Product List"}
          width={"70%"}
          visible={isModalOpen === true}
          onClose={() => setIsModalOpen(false)}
        >
          <div className="d-inline-block border border-2">
            <div className="item-flex">
              <h5 className="m-3 d-block">
                Name:{" "}
                <span className="font-weight-normal ms-2">
                  {customerDetails.customerName}
                </span>
              </h5>
              <h5 className="m-3 d-block">
                State:{" "}
                <span className="font-weight-normal ms-2">
                  {customerDetails.customerState}
                </span>
              </h5>
              <h5 className="m-3 d-block">
                Invoice No:{" "}
                <span className="font-weight-normal ms-2">
                  {customerDetails.customerInvoice}
                </span>
              </h5>
              <h5 className="m-3 d-block">
                Number:{" "}
                <span className="font-weight-normal ms-2">
                  {customerDetails.customerNumber}
                </span>
              </h5>
              <h5 className="m-3 d-block">
                Date:{" "}
                <span className="font-weight-normal ms-2">
                  {moment(customerDetails.purchaseDate).format("DD-MMM-YYYY")}
                </span>
              </h5>
            </div>
          </div>
          <table className="table table-bordered mt-4">
            <thead>
              <tr>
                <td>Sr no.</td>
                <td>Product Name</td>
                <td>Report Type</td>
                <td>Mfg Date</td>
                <td>Exp Date</td>
                <td>Batch</td>
                <td>Qty.</td>
                <td>Rate</td>
                <td>Dis Amt.</td>
                <td>Taxable Amt.</td>
                {Report.some(
                  (item) => item.cgstAmount !== 0 && item.sgstAmount !== 0
                ) ? (
                  <>
                    <td>CGST Amt.</td>
                    <td>SGST Amt.</td>
                  </>
                ) : (
                  <td>IGST Amt.</td>
                )}
                <td>Net Amt.</td>
              </tr>
            </thead>
            <tbody>
              {Report.map((item, index) => (
                <tr key={item.id}>
                  <td>{index + 1}</td>
                  <td>{item.productName}</td>
                  <td>{item.reportType}</td>
                  <td>{item.manufacturingDate}</td>
                  <td>{item.expiryDate}</td>
                  <td>{item.batchNo}</td>
                  <td>{item.quantity}</td>
                  <td>Rs. {item.price}</td>
                  <td>
                    Rs. {item.discountAmount} ({item.discount}%)
                  </td>
                  <td>Rs. {item.taxableAmount}</td>
                  {item.cgstAmount === 0 && item.sgstAmount === 0 ? (
                    <>
                      <td>
                        Rs. {item.igstAmount} ({item.gst}%)
                      </td>
                    </>
                  ) : (
                    <>
                      <td>
                        Rs. {item.cgstAmount} ({item.gst / 2}%)
                      </td>
                      <td>
                        Rs. {item.sgstAmount} ({item.gst / 2}%)
                      </td>
                    </>
                  )}

                  <td>Rs. {item.netAmount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal>
      )}
    </PageHeader>
  );
}

export default CreditNoteList;
